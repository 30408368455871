<template>
  <q-dialog v-model="dialogState" position="right" maximized>
    <q-card class="addAddressDialogue">
      <q-card-section>
        <div class="topHeader">
          <h3>
            <q-btn v-if="isMobile" flat dense>
              <SvgIcon
                className="q-mr-xs"
                size="1rem"
                icon="back-arrow-line"
                v-close-popup
              />
            </q-btn>
            Select Address</h3
          >
          <q-btn
            v-if="!isMobile"
            unelevated
            flat
            round
            icon="close"
            v-close-popup
            class="close"
          />
        </div>
        <div class="middle">
          <div class="scheduleInner">
            <div class="addressSelect">
              <div class="row">
                <div class="col-9 col-sm-6">
                  <template v-if="selectedAddress">
                    <h6>
                      {{
                        selectedAddress.firstName +
                        ' ' +
                        selectedAddress.lastName
                      }}
                    </h6>
                    <p
                      class="ellipsis-2-lines"
                      v-html="$options.filters.formatAddress(selectedAddress)"
                    />
                  </template>
                </div>
                <div class="col-3 col-sm-3 offset-sm-3 addbtn">
                  <q-btn
                    :rounded="!isMobile"
                    no-caps
                    unelevated
                    color="primary"
                    :label="isMobile ? '+' : 'Add Address'"
                    :class="
                      isMobile
                        ? 'q-mt-md'
                        : 'q-pr-lg q-pl-lg q-mt-md full-width'
                    "
                    :size="isMobile ? '2rem' : '1rem'"
                    @click="
                      changeDialogState({
                        dialog: 'addShippingAddress',
                        val: true,
                        parentDialog: 'addressListDialog',
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <q-scroll-area
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              class="reserve-scroll-area"
            >
              <div class="searchAddress">
                <q-list class="searchAddressList">
                  <q-item
                    v-for="(address, index) in addresses"
                    :key="`${address.addressId} ${index}`"
                  >
                    <q-item-section class="selected">
                      <q-item-label class="title">
                        {{ `${address.firstName} ${address.lastName}` }}
                        <span v-if="address.isDefault"> Default</span>
                      </q-item-label>
                      <q-item-label
                        v-html="$options.filters.formatAddress(address)"
                        class="address"
                      />
                      <q-item-label
                        v-if="
                          fulfillmentCode == 'scd' &&
                          address.isDeliver === false
                        "
                        class="text-red delivery-address-note"
                      >
                        *Unable To Deliver to This Location
                      </q-item-label>
                      <q-item-label
                        v-if="selectedAddress.addressId != address.addressId"
                        class="action"
                      >
                        <q-btn
                          unelevated
                          no-caps
                          :outline="isMobile"
                          :rounded="!isMobile"
                          color="tertiary"
                          @click="chooseAddress(address)"
                          label="Choose address"
                          class="q-px-md"
                        />
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </q-scroll-area>
          </div>
        </div>
        <div class="bottom">
          <q-btn
            unelevated
            no-caps
            :rounded="!isMobile"
            outline
            color="tertiary"
            label="Cancel"
            v-close-popup
            class="q-ml-sm q-mr-sm q-px-md q-py-xs"
          />
          <q-btn
            unelevated
            no-caps
            :rounded="!isMobile"
            color="primary"
            label="Save and continue"
            class="q-ml-sm q-mr-sm q-px-md q-py-xs"
            type="submit"
            @click="saveAndContinue()"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddressListDialog',
  props: {
    fulfillmentCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lastSelectedAddress: null,
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('addressListDialog')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'addressListDialog',
          val,
        })
      },
    },
    selectedAddress() {
      if (this.lastSelectedAddress) {
        return this.lastSelectedAddress
      }
      return this.selectedAddressForFulfillmentCode(this.fulfillmentCode)
    },
    ...mapGetters('address', ['addresses']),
    ...mapGetters('persistedLocal', ['selectedAddressForFulfillmentCode']),
  },
  methods: {
    chooseAddress(address) {
      this.lastSelectedAddress = address
    },
    saveAndContinue() {
      if (this.lastSelectedAddress) {
        this.$store.commit('address/SET_FULFILLMENT_ADDRESS', {
          address: this.lastSelectedAddress,
          fulfillmentCode: this.fulfillmentCode,
        })
        // this.$store.commit('persistedLocal/SET_SELECTED_ADDRESS', {
        //   address: this.lastSelectedAddress,
        //   fulfillmentCode: this.fulfillmentCode,
        // })
      }
      this.lastSelectedAddress = null
      this.dialogState = false
    },
  },
}
</script>

<style lang="scss">
.addAddressDialogue {
  width: 100vw !important;
  @media (min-width: 481px) {
    width: 440px !important;
  }
  @media (min-width: 1024px) {
    width: 860px !important;
  }
  .form-field,
  .form-action {
    padding-bottom: 16px;
    .q-field--with-bottom {
      padding-bottom: 0px;
    }
    @media (max-width: 767px) {
      .q-field__bottom {
        position: relative;
        padding: 0;
        margin-bottom: 5px;
      }
    }
  }

  .q-card__section {
    padding: 0;
  }
  .topHeader {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .back {
      .q-focus-helper {
        display: none;
      }
      .q-btn__wrapper {
        padding: 0 0 4px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .middle {
    padding: 30px 30px;
    @media (max-width: 767px) {
      padding: 15px 15px;
    }
    .addAddressHeight {
      padding-right: 20px;
      height: calc(100vh - 204px);
      @media (max-width: 767px) {
        height: calc(100vh - 175px);
      }
    }
  }
  .bottom {
    padding: 10px 30px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
    .q-btn {
      width: 180px;
      margin: 7px 0;
      padding: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        padding: 5px 8px;
      }
    }
    .q-btn:last-child {
      float: right;
    }
  }
  /* Mobile: Do not remove this css */
  @media (max-width: 1023px) {
    .reserve-scroll-area {
      height: calc(100vh - 295px);
    }
    .addressSelect {
      padding: 20px;
      padding-bottom: 25px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.2);
      background: #f5f5f5;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -15px;
      h6 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
        color: $tertiary;
      }
      p {
        margin: 0;
      }
      .addbtn {
        text-align: center;
        .q-btn {
          height: 50px;
          .q-btn__wrapper {
            min-height: 50px;
          }
          .q-btn__content {
            height: 42px;
            .block {
              line-height: normal;
            }
          }
        }
      }
    }
    .bottom {
      padding: 5px 15px;
      .q-btn {
        width: 48%;
        .q-btn__wrapper {
          padding: 0;
        }
      }
    }
    .searchAddressList {
      .q-item {
        padding: 12px;
        margin: 10px 0;
        background: #f1fbff;
        border: 1px solid #c4e7f5;
        border-radius: 5px;
        .q-item__label {
          font-size: 14px;
          line-height: 20px !important;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          color: $tertiary;
          span {
            display: inline-block;
            padding: 3px 10px;
            margin: 3px;
            font-size: 12px;
            line-height: normal;
            background: #91bf5b;
            color: #fff;
            border-radius: 12px;
          }
        }
        .action {
          margin-top: 10px;
        }
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .delivery-address-note {
    .searchAddressList {
      .address {
        padding-bottom: 30px !important;
      }
      .delivery-address-note {
        font-size: 11px !important;
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 70px;
        text-align: center;
      }
    }
    .reserveTimeSlot
      .scheduleList
      .searchAddress
      .searchAddressList
      .q-item
      .q-item__section
      .q-item__label.delivery-address-note {
      line-height: 13px !important;
    }
  }
  /* Mobile: Do not remove this css */
}
</style>
